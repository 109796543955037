.portfolio {
    margin: 0 auto 40px;
    max-width: 1140px;
    min-height: 1000px;
}

.portfolio__container {
    display: flex;
    min-height: 600px;
    margin-bottom: 60px;
}

.portfolio__number {
    margin: 0;
    max-width: 500px;
    width: 100%;
    max-height: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    color: #FFFFFF;

    font-size: 200px;
    line-height: 210px;
}

.portfolio__info {
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.portfolio__title {
    margin: 0;
    min-height: 50px;

    color: #FFFFFF;
}

.portfolio__text {
    margin: 0;
    color: #FFFFFF;
    text-align: justify;
    text-indent: 20px;
}

.portfolio__pages {
    display: flex;
    gap: 40px;
}

.portfolio__link {
    margin: 16px auto 0;

    font-weight: 100;
    font-size: 14px;
    line-height: 16px;
    text-align: left;

    color: #FFFFFF;
    text-decoration: underline;
}

.portfolio__link:hover {
    opacity: 0.7;
    transition: 0.5s;
}

.portfolio__img {
    margin-top: 30px;
    max-width: 640px;
    width: 100%;
    height: 500px;

    border: #ffffff 2px solid;
    object-fit: contain;

    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
}

@media (max-width: 1200px) {
    .portfolio {
        max-width: 980px;
    }

    .portfolio__info {
        max-width: 490px;
    }

    .portfolio__img {
        max-width: 486px;
        height: 500px;
    }
}

@media (max-width: 1020px) {
    .portfolio {
        max-width: 780px;
    }

    .portfolio__number {
        max-width: 200px;
        max-height: 110px;
        font-size: 50px;
        line-height: 60px;
    }
}

@media (max-width: 820px) {
    .portfolio {
        max-width: 650px;
    }

    .portfolio__container {
        margin: 0 auto 40px;
        max-width: 496px;
        flex-direction: column;
    }

    .portfolio__img {
        height: 450px;
    }
}

@media (max-width: 560px) {
    .portfolio {
        max-width: 300px;
    }

    .portfolio__container {
        margin-bottom: 20px;
        max-width: 300px;
    }

    .portfolio__img {
        margin-top: 15px;
        max-width: 288;
        width: 100%;
        height: 300px;
    
        border-width: 1px;
    }
}
.header {
    margin: 30px 70px 0;
    max-height: 30px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.header__logo {
    display: flex;
    align-items: center;
}

.header__name {
    margin: 0;

    font-weight: 100;
    font-size: 17px;
    line-height: 20px;
    color: #09d064;
}

.header__name:hover {
    opacity: 0.7;
    transition: 0.5s;
}

.header__nav {
    display: flex;
    gap: 20px;
    align-items: center;
    text-decoration: none;
}

a:link, a:visited {
    text-decoration: none;
}

.header__link {
    color: #09d064;
    cursor: pointer;
}

.header__link:hover {
    opacity: 0.7;
    transition: 0.5s;
}

.header__title {
    font-weight: 100;
    font-size: 15px;
}

@media (max-width: 580px) {
    .header {
        flex-direction: column;
    }
}
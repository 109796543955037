.aboutMe {
    margin: 0 auto;
    max-width: 1140px;
    min-height: 1000px;
}

.aboutMe__title {
    margin: 0 auto;
    padding: 80px 0 30px;
    max-width: 1140px;
    min-height: 27px;

    color: #FFFFFF;

    font-size: 110px;
    text-align: left;
}

.aboutMe__title span {
    display: block;
    opacity: 0;
    transform: translateY(20px);
    animation: flyIn 0.5s forwards;
}

.aboutMe__title span:nth-child(1) {
    animation-delay: 0.3s;
}

.aboutMe__title span:nth-child(2) {
    animation-delay: 0.6s;
}

.aboutMe__title span:nth-child(3) {
    animation-delay: 0.9s;
}

@keyframes flyIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.aboutMe__subtitle {
    color: #FFFFFF;
}

.aboutMe__stack {
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    gap: 10px;
    color: #FFFFFF;
    list-style: none;
}

.aboutMe__stack-item {
    min-width: 90px;
    height: 30px;
    display: flex;
    justify-content: center;
    
    font-size: 14px;
    line-height: 17px;

    align-items: center;
    background-color: #303030;
    color: #fff;
    border: none;
    border-radius: 20px;
}

.aboutMe__container {
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
}

.aboutMe__name {
    margin: 0;
    color: #FFFFFF;
}

.aboutMe__info {
    max-width: 600px;
    min-height: 327px;

    text-align: left;
    color: #FFFFFF;
}

.aboutMe__info-text {
    min-height: 88px;
    margin: 30px auto 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    text-indent: 20px; 
}

.aboutMe__info-photo {
    max-width: 270px;
    width: 100%;
    height: 327px;
    margin-top: 30px;
    border-radius: 10px;
    object-fit: cover;
  }

@media (max-width: 1220px) {
    .aboutMe {
        max-width: 1000px;
    }

    .aboutMe__title {
        max-width: 1210px;
    }
}

@media (max-width: 1030px) {
    .aboutMe {
        max-width: 800px;
    }

    .aboutMe__title {
        max-width: 1020px;
    }

    .aboutMe__info {
        max-width: 400px;
    }
}

@media (max-width: 830px) {
    .aboutMe {
        max-width: 700px;
    }

    .aboutMe__title {
        padding-top: 60px;
        padding-bottom: 20px;
        max-width: 820px;
    
        font-size: 90px;
    }

    .aboutMe__subtitle {
        max-width: 820px;
    }

    .aboutMe__stack {
        flex-direction: column;
    }
    
    .aboutMe__stack-item {
        min-width: 80px;
    }

    .aboutMe__info {
        max-width: 300px;
    }
}

@media (max-width: 768px) {
    .aboutMe {
        max-width: 668px;
    }
    
    .aboutMe__title {
        max-width: 758px;
    }

    .aboutMe__subtitle {
        max-width: 560px;
    }
    
    .aboutMe__stack-item {
        min-width: 80px;
    }
    
    .aboutMe__info {
        max-width: 250px;
    }
    
    .aboutMe__info-text {
        margin-top: 20px;
        min-height: 88px;
        font-size: 12px;
        line-height: 18px;
    }
    
    .aboutMe__info-photo {
        max-width: 255px;
        height: 307px;
      }
}

@media (max-width: 720px) {
    .aboutMe {
        max-width: 540px;
    }

    .aboutMe__title {
        max-width: 710px;
        padding-top: 60px;
        font-size: 70px;
    }

    .aboutMe__container {
        display: grid;
        justify-content: center;
    }
    
    .aboutMe__info {
        max-width: 540px;
        min-height: 301px;
        grid-row: 2;
    }
    
    .aboutMe__info-text {
        min-height: 60px;
        font-size: 14px;
        line-height: 18px;
    }

    .aboutMe__info-photo {
        margin-top: 40px;
        max-width: 540px;
        height: 352px;
        grid-row: 3;
      }
}

@media (max-width: 560px) {
    .aboutMe {
        max-width: 320px;
    }
    
    .aboutMe__title {
        max-width: 292px;
        font-size: 50px;
    }
 
    .aboutMe__info {
        max-width: 292px;
    }

    .aboutMe__info-photo {
        max-width: 292px;
        object-fit: cover;
      }
}

@media (max-width: 320px) {
    .aboutMe__title {
        font-size: 40px;
    }
}